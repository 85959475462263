import React from 'react'
import { Link } from 'gatsby'

const NavFooter = ({ menu }) => (
    <nav className="menu__nav" id="navigation-footer" aria-label="Main Navigation">
        <ul className="menu__list">
        {menu.map(item => (
            <li className="menu__list-item" key={item.wordpress_id}>
                <Link className="menu__list-link" to={`${item.url}`} tabIndex="-1">{item.title}</Link>
            </li>
        ))}
        </ul>
    </nav>
)
  
export default NavFooter