import React, { Component } from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'

export default class Page extends Component {
  render() {
    const { data } = this.props;
    const pageWrapperClasses = `page__wrapper ${data.wordpressPage.slug}`;
    return (
      <Layout>
        <SEO 
          title={data.wordpressPage.title} 
          keywords={[`gatsby`, `application`, `react`]} />
        <div className={pageWrapperClasses}>
          <h1 class="page__title">{data.wordpressPage.title}</h1>
          <div dangerouslySetInnerHTML={{ __html: data.wordpressPage.content }} />
        </div>
      </Layout>
    )
  }
}

export const query = graphql`
  query PageQuery($slug: String!) {
    wordpressPage(slug: {eq: $slug}) {
      slug
      title
      content
    }
  }
`
