import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import { Link } from 'gatsby'
import NavMain from './nav-main'

// import IconTwitter from '../assets/paths/icon-twitter'
// import IconGithub from '../assets/paths/icon-github'
// import IconInstagram from '../assets/paths/icon-instagram';


class Header extends Component {
  componentDidMount = () => {
    const menuButton    = document.querySelector('#navigation-btn');
    const menuLinks     = document.querySelectorAll('.nav__main-link');
    const navContainer  = document.querySelector('.nav__wrapper');
    
    menuButton.addEventListener('click', toggleOpen);
    
    document.body.style.overflow = "auto";        // enable scrolling
    document.body.classList.remove('menu-open');  // clear menu class
    
    let state = false;  // set the initial menu open state
    
    function toggleOpen(e) {
      e.preventDefault();
      state = !state;  // toggle state boolean
      
      this.classList.toggle('open');  // toggle hamburger button class
      this.parentElement.classList.toggle('open');  // toggle hamburger wrapper
      navContainer.classList.toggle('open');  // toggle nav overlay class
      //document.body.classList.toggle('menu-open');  // toggle body class
      
      if(state) {
        this.setAttribute('aria-expanded', 'true');   // set aria expanded
        setTimeout(() => {
          document.body.style.overflow = "hidden";      // disable scrolling
        }, 300);
        document.body.classList.add('menu-open');  // toggle body class
      } else {
        this.setAttribute('aria-expanded', 'false');  // set aria collapsed
        document.body.style.overflow = "auto";        // enable scrolling
        document.body.classList.remove('menu-open');  // toggle body class
      }

      Array.prototype.forEach.call(menuLinks, link => {
        if(state) {
          link.setAttribute('tabindex', '0'); // enable tabindex - keyboard a11y 
        } else {
          link.setAttribute('tabindex', '-1');  // remove tabindex - keyboard a11y
        }
      });
      
    }
  }
  
  render() {
    return (
      <header className="header__wrapper">
        <div className="header__wrapper-inner">

          <div className="name__wrapper">
            <Link to="/" className="name__link">Jasen <span>Wyatt</span></Link>
          </div>

          <div className="menu__wrapper">
            <button className="menu__icon" id="navigation-btn" aria-label="navigation menu" aria-controls="navigation" aria-expanded="false"></button>
          </div>

          <div className="nav__wrapper">
            <StaticQuery
              query={graphql`
                query SiteMenuQuery {
                  wordpressWpApiMenusMenusItems {
                    items {
                      title
                      object_slug
                      wordpress_id
                      url
                    }
                  }
                }
              `}
              render={data => (
                <NavMain menu={data.wordpressWpApiMenusMenusItems.items}/>
              )}
            />
          </div>

        </div>
      </header>
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
