import React, { Component } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import NavFooter from './nav-footer'

export default class Footer extends Component {
  render() {
    return (
      <footer className="container__full">

        <section className="row footer__details">
          <div className="col">
            <h4>Services</h4>
            <ul>
              <li>Website Design &amp; Development</li>
              <li>User Experience</li>
              <li>User Interface Design</li>
              <li>Information Architecture</li>
              <li>Content Management Systems</li>
              <li>Prototyping</li>
              <li>Technology Consulting</li>
            </ul>
          </div>
          <div className="col">
            <h4>Contact</h4>
            <ul>
              <li>Jasen Wyatt <span role="img" aria-label="wave">👋</span></li>
              {/* <li>Email: <a href="mailto:hello@jasen.dev" title="Email Jasen Wyatt">hello@jasen.dev</a></li> */}
              <li>Twitter: <a href="https://twitter.com/jas3n" title="Follow Jasen on Twitter" rel="noopener noreferrer" target="_blank">@jas3n</a></li>
              <li>LinkedIn: <a href="https://www.linkedin.com/in/jasenwyatt/" title="Find Me on LinkedIn" rel="noopener noreferrer" target="_blank">/jasenwyatt</a></li>
              <li>GitHub: <a href="https://github.com/jasenwyatt" title="Follow Jasen on GitHub" rel="noopener noreferrer" target="_blank">jasenwyatt</a></li>
            </ul>
          </div>
        </section>

        <section className="row menu">
          <StaticQuery
              query={graphql`
                query MenuFooterQuery {
                  wordpressWpApiMenusMenusItems {
                    items {
                      title
                      object_slug
                      wordpress_id
                      url
                    }
                  }
                }
              `}
              render={data => (
                <NavFooter menu={data.wordpressWpApiMenusMenusItems.items}/>
              )}
          />
        </section>

        <section className="row disclaimer">
          © MMXXI &bull; Jasen Wyatt &amp; Valid Cat, LLC. &bull; All Rights Reserved.
        </section>

        <div className="planet-container">
          <div className="planet"></div>
          <div className="satellites"></div>
        </div>

      </footer>
    )
  }
}
